import ApiService from '../index';
import UtilityEndpoint from './types/endpoints';
import { FetchDistributionRates, FetchUtilityByZipCode } from './types/query';
import { FetchAllUtilitiesResponse, FetchDistroRateResponse, FetchUtilityResponse } from './types/read';

export default class UtilityApiService {
  static async fetchUtility(query: FetchUtilityByZipCode): Promise<FetchUtilityResponse | undefined> {
    const response = await ApiService.get(
      UtilityEndpoint.FETCH_UTILITY(query.zip_code),
    );
    return response?.data;
  }

  static async fetchDistributionRate(query: FetchDistributionRates): Promise<FetchDistroRateResponse | undefined> {
    const response = await ApiService.get(
      UtilityEndpoint.FETCH_DISTRIBUTION_RATE(query.utility_id),
      query,
    );
    return response?.data;
  }

  static async fetchAllUtilities(): Promise<FetchAllUtilitiesResponse> {
    const response = await ApiService.get(
      UtilityEndpoint.FETCH_ALL_UTILITIES(),
      {},
    );
    return response?.data;
  }
}
