<template>
  <div class="hello">
    <h1 class="mt-5 pt-5">
      404
    </h1>
    <p class="bold">
      This page does not exist!
    </p>
    <div class="text-center">
      <a
        class="button"
        href="/"
      >
        <i class="icon-home" /> Go back home, you're lost.
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {}
</script>

<style scoped>
.hello {
  background-color: #132435;
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
}

.button {
  font-weight: 300;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  border: 1px solid #efefef;
  padding: 0.5em;
  border-radius: 3px;
  float: left;
  margin: 6em 0 0 -100px;
  left: 50%;
  position: relative;
  transition: all 0.3s linear;
}

.button:hover {
  color: #fff;
}

p {
  font-size: 2em;
  text-align: center;
  font-weight: 100;
}

h1 {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
}
</style>
