import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';
import VueAxios from 'vue-axios';
import JwtService, { ID_ACCESS_TOKEN_KEY, ID_REFRESH_TOKEN_KEY } from '../jwt';
import { API_URL } from '../../config';
import errorModule from '../../store/modules/error.module';
import { ClientErrorKind, StatusCodes } from './types/enum';
import prettifyErrorMessages, { isJwtExpired } from './errorParser';

enum OriginFunction {
  POST,
  GET,
  DELETE,
}

export default class ApiService {
  init(): void {
    Vue.use(VueAxios, axios);
  }

  /**
   * Sets Authorization header, which is a JWT access_token
   * @param JWT_ID
   */
  static setHeaderFromJWT(JWT_ID = ID_ACCESS_TOKEN_KEY): void {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken(JWT_ID)}`;
  }

  static setAuthHeader(token: string): void {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  static removeAuthHeader(): void {
    delete Vue.axios.defaults.headers.common.Authorization;
  }

  /**
   * Takes endpoint + optional query parameters, issues a GET request
   * through axios. Returns undefined if there was an error.
   * @param resource
   * @param params
   * @param showLoader
   * @param ignoreError
   */
  static async get(resource: string, params = {}, showLoader = true, ignoreError = false): Promise<AxiosResponse | undefined> {
    try {
      errorModule.setLoading(showLoader);
      const response = await Vue.axios.get(`${API_URL}${resource}`, { params });
      errorModule.setLoading(false);
      return response;
    } catch (error) {
      // Log the error in console if ENVIRONMENT is not prd.
      if (process.env.ENVIRONMENT !== 'prd') {
        console.dir(error);
      }

      if (ignoreError) {
        errorModule.setLoading(false);
        return;
      }
      // Check if the response needs to retry due to an expired JWT
      const errorResponse = await ApiService.handleAPIError(error, resource, params, showLoader, OriginFunction.GET);
      if (!errorResponse) {
        return;
      }
      return errorResponse;
    }
  }

  /**
   * Takes resource endpoint + optional parameters, issues a POST request
   * through axios. Returns undefined if there was an error.
   * @param resource
   * @param params
   * @param showLoader
   * @param ignoreError
   */
  static async post(resource: string, params = {}, showLoader = true, ignoreError = false): Promise<AxiosResponse | undefined> {
    try {
      errorModule.setLoading(showLoader);
      const response = await Vue.axios.post(API_URL + resource, params);
      errorModule.setLoading(false);
      return response;
    } catch (error) {
      if (!showLoader) {
        return;
      }
      // Log the error in console if ENVIRONMENT is not prd.
      if (process.env.ENVIRONMENT !== 'prd') {
        console.dir(error);
      }

      if (ignoreError) {
        errorModule.setLoading(false);
        return;
      }
      // Check if the response needs to retry due to an expired JWT
      const errorResponse = await this.handleAPIError(error, resource, params, showLoader, OriginFunction.POST);
      if (!errorResponse) {
        return;
      }
      return errorResponse;
    }
  }

  /**
   * Takes resource endpoint + optional parameters, issues a DELETE request
   * through axios. Returns undefined if there was an error.
   * @param resource
   * @param params
   * @param showLoader
   */
  static async delete(resource: string, params = {}, showLoader = true, ignoreError = false): Promise<AxiosResponse | undefined> {
    try {
      errorModule.setLoading(showLoader);
      const response = await Vue.axios.delete(API_URL + resource, params);
      errorModule.setLoading(false);
      return response;
    } catch (error) {
      if (!showLoader) {
        return;
      }
      if (ignoreError) {
        errorModule.setLoading(false);
        return;
      }
      // Check if the response needs to retry due to an expired JWT
      const errorResponse = await this.handleAPIError(error, resource, params, showLoader, OriginFunction.DELETE);
      if (!errorResponse) {
        return;
      }
      return errorResponse;
    }
  }

  /**
   * Handle the errors from the network requests.
   * @param error
   * @param resource
   * @param params
   * @param showLoader
   * @param origin
   */
  private static async handleAPIError(error, resource: string, params, showLoader: boolean, origin: number):
    Promise<AxiosResponse | undefined> {
    errorModule.setLoading(false);

    if (!error?.response) {
      errorModule.throwError(prettifyErrorMessages(ClientErrorKind.Generic));
      return;
    }

    // Check if the server is currently in maintenance mode.
    if (ApiService.isMaintenanceMode(error.response)) {
      return;
    }

    const errorMessage = error.response.data.message;
    if (!JwtService.checkIfTokenExists(ID_REFRESH_TOKEN_KEY)) {
      errorModule.throwError(prettifyErrorMessages(errorMessage));
      return;
    }

    // Check if the message is regarding the JWT expiring.
    const jwtIsExpired = await isJwtExpired(errorMessage);
    if (jwtIsExpired) {
      // If the JWT was expired, recall the request after the tokens refresh
      let response: AxiosResponse | undefined;
      switch (origin) {
        case OriginFunction.POST:
          response = await ApiService.post(resource, params, showLoader, true);
          break;
        case OriginFunction.GET:
          response = await ApiService.get(resource, params, showLoader, true);
          break;
        case OriginFunction.DELETE:
          response = await ApiService.delete(resource, params, showLoader, true);
          break;
        default:
          return;
      }
      return response;
    }
    // Check if error was handled by the API
    if (!errorMessage) {
      console.error(`[ERROR] ApiService ${errorMessage}`);
      errorModule.throwError(prettifyErrorMessages(errorMessage));
      return;
    }
    console.error(`[ERROR] ApiService ${errorMessage}`);
    errorModule.throwError(prettifyErrorMessages(errorMessage));
  }

  /**
   * Check if the status code indicates that the site is down for maintenance
   * @param response
   */
  static isMaintenanceMode(response: AxiosResponse | undefined): boolean | undefined {
    if (!response) {
      return;
    }
    // Check if the status code is a 503
    if (response.status === StatusCodes.MaintenanceMode) {
      errorModule.setMaintenanceMode();
      return true;
    }
    // If there is no 503, set maintenance mode to false. If the maintenance
    // header is found afterward, the state will reflect that.
    errorModule.setMaintenanceMode(false);
    // If there are no headers set, return.
    if (!response.headers) {
      return;
    }
    // If the status is not a 503, check if there's a x-gridlite-maintenance header.
    if (!response.headers['x-gridlite-maintenance']) {
      return;
    }
    // If the header exists, and equals 1, then the API is down for maintenance
    const offlineStatus: string = response.headers['x-gridlite-maintenance'];
    if (offlineStatus !== '1') {
      return;
    }
    errorModule.setMaintenanceMode();
    return true;
  }
}
