export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const SET_LOADER = 'SET_LOADER';
export const SKIP_UTILITY_ACCOUNT_DIALOG = 'SKIP_UTILITY_ACCOUNT_DIALOG';
export const CONFIRM_SKIP_UTILITY_ACCOUNT = 'CONFIRM_SKIP_UTILITY_ACCOUNT';
export const SEARCH_FILTER_UPDATED = 'SEARCH_FILTER_UPDATED';
export const SEARCH_SORT_UPDATED = 'SEARCH_SORT_UPDATED';
export const SHOW_LEAVING_DIALOG = 'SHOW_LEAVING_DIALOG';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RETURN_TO_LOGIN = 'RETURN_TO_LOGIN';
export const SHOW_PENDING_SWITCH_DIALOG = 'SHOW_PENDING_SWITCH_DIALOG';
export const SHOW_REGISTER_OIL_SUPPLIER_DIALOG = 'SHOW_REGISTER_OIL_SUPPLIER_DIALOG';
