import { BankAccount } from '@/models/user.model';

export interface Expiration {
    date: string;
    soon_range: number;
    expires_soon: boolean;
}

export interface Contract {
    supplier_id: string,
    rate_as_dollars: string,
    expiration: Expiration,
    status: string,
    rate_as_cents?: string,
    cancellation_fee?: number,
    enrollment_fee?: number,
    estimated_average_monthly_cost?: Record<string, string>;
}

export interface CompletedPayment {
    amount: number;
    user_id: string;
    date_paid: string;
}

export type ReadingPaymentStatus =
    | 'InProgress'
    | 'Paid'
    | 'Completed'
    | 'Failed'
    | 'NoneSet'
    | 'PaymentAlreadyCompleted';

export interface Reading {
    usage_amount: number;
    stated_cost_of_usage_amount: number;
    date: string;
    completed_payments: CompletedPayment[];
    completed_payments_summed_amount: number;
    payment_status: ReadingPaymentStatus;
}

export interface UtilityAccountPaymentMember {
    user_name: string;
    user_id: string;
    bill_payment_percentage?: number;
    payment_method?: 'CreditCard' | { BankAccount: BankAccount };
}

export type SplitBillStatus =
    | 'PendingMembers'
    | 'Active'
    | 'Cancelled'
    | 'NotSet';

export interface SplitBillStatusActiveLinked {
    ActiveBundled: {
        linked_meters?: string[];
    };
    PendingMembersBundled: {
        linked_meters?: string[];
    };
}

export type MeterCommodityType = 'Electricity' | 'Gas';

export interface Meter {
    label: string;
    readings: Reading[];
    service_reference_number: string;
    distribution_rate: string;
    payment_members: UtilityAccountPaymentMember[];
    split_bill_status: SplitBillStatus | SplitBillStatusActiveLinked;
    commodity: MeterCommodityType;
}

export interface ActiveContract {
    supplier_id: string,
    rate_as_dollars: string,
    expiration: Expiration,
    status: string,
    rate_as_cents?: string,
    cancellation_fee?: number,
    enrollment_fee?: number,
    estimated_average_monthly_cost?: Record<string, string>;
    switch_status: string;
}

export interface Contracts {
    active?: ActiveContract,
    past: Contract[],
}

export interface MeterRecord {
    contracts: Contracts,
    meter: Meter,
}

export interface SummarizedReading {
    commodity: MeterCommodityType;
    stated_cost: string;
    service_reference_number: string;
    date: string;
    payment_status: ReadingPaymentStatus;
}

export interface SyncedReading {
    // combined based on the same 14-day cycle
    synced_readings: SummarizedReading[];
    // The total cost of the synced readings. This equates to the
    // total cost of the bill for the month. For example. if there was
    // a gas meter with a cost of $40, and an electricity meter with a
    // cost of $60, the summed_cost would be $100.
    summed_usage_cost: string;
    summed_completed_payments_amount: string;
    // maps Commodity enum type to scale-2 Decimal value
    summed_cost_by_commodity: { [key: string]: string };
}

export interface PaymentBundle {
    // The meters that are split within this bundle.
    linked_service_reference_numbers: string[];
    // A synced reading is a combined reading of all the readings from the meters
    // in the bundle. All readings are within 14 days of each other.
    readings: SyncedReading[];
}

export interface UtilityAccountProperty {
    reference_id: string;
    label: string;
    meter_records: MeterRecord[];
    bundles: PaymentBundle[];
}

export interface UtilityAccount {
    id: string,
    utility_id: string,
    account_number: string,
    status: string,
    user_id_of_owner: string,
    customer_name_key?: string,
    properties: UtilityAccountProperty[],
}

export function instanceOfSplitBillStatusActiveLinked(
  type: SplitBillStatusActiveLinked | string,
): type is SplitBillStatusActiveLinked {
  return typeof type !== 'string';
}
