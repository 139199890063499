import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import utilityaccountModule from '@/store/modules/utilityaccount.module';
import propertyModule from '@/store/modules/property.module';
import electricSupplierModule from '@/store/modules/electricSupplier.module';
import utilityModule from '@/store/modules/utility.module';
import { instanceOfSplitBillStatusActiveLinked } from '@/models/utilityAccount.model';

function buildPublicImportPath(fileName: string) {
  return import(`@/pages/public/${fileName}`);
}

function buildDashboardImportPath(fileName: string) {
  return import(`@/pages/dashboard/${fileName}`);
}

/** *  ---  Public Routes  --- */

export const LOGIN_PAGE: RouteConfig = {
  name: 'Login',
  path: '',
  component: () => buildPublicImportPath('LoginPage'),
  meta: {
    title: 'Login',
  },
};

/** *  ---  Dashboard Routes  --- */

export const UTILITY_ACCOUNT_PAGE: RouteConfig = {
  name: 'UtilityAccountPage',
  path: '',
  component: () => buildDashboardImportPath('UtilityAccountPage.vue'),
  meta: {
    title: 'Utility Accounts',
  },
  async beforeEnter(_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> {
    await Promise.all([
      utilityModule.fetchAllUtilities(),
      utilityaccountModule.fetchAllUtilityAccounts()
        .then(async () => {
          const promises: Promise<void>[] = [];
          Object.values(utilityaccountModule.allUtilityAccounts).forEach((ua) => {
            if (ua.properties.length === 0) return;
            if (ua.properties[0]?.reference_id) {
              promises.push(propertyModule.fetchProperty({
                property_id: ua.properties[0].reference_id,
              }));
            }
            if (ua.properties[0]?.meter_records[0]?.contracts.active?.supplier_id) {
              promises.push(electricSupplierModule.fetchSupplier({
                supplier_id: ua.properties[0].meter_records[0].contracts.active.supplier_id,
              }));
            }
          });
          await Promise.all(promises);
        }),
    ]);
    next();
  },
};

export const SPLIT_BILLING_PAGE: RouteConfig = {
  name: 'SplitBillingPage',
  path: 'split',
  component: () => buildDashboardImportPath('SplitBillPage.vue'),
  meta: {
    title: 'Split Billing',
  },
  async beforeEnter(_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> {
    await Promise.all([
      utilityModule.fetchAllUtilities(),
      utilityaccountModule.fetchAllUtilityAccounts()
        .then(() => {
          const splitBillEuas = Object.values(utilityaccountModule.allUtilityAccounts)
            .filter(
              (ua) => ua.properties
                .some((p) => p.meter_records
                  .some((mr) => mr.meter.split_bill_status === 'Active' || (instanceOfSplitBillStatusActiveLinked(mr.meter.split_bill_status) && mr.meter.split_bill_status.ActiveBundled))),
            );
          splitBillEuas.forEach((ua) => {
            utilityaccountModule.updateUtilityAccountsWithSplitBilling(ua);
          });
        }),
    ]);
    next();
  },
};

export const USER_PAGE: RouteConfig = {
  name: 'UserPage',
  path: 'users',
  component: () => buildDashboardImportPath('UserPage.vue'),
  meta: {
    title: 'Users',
  },
};

const ALL_ROUTES = {
  PUBLIC: [
    LOGIN_PAGE,
  ],
  APP: [
    UTILITY_ACCOUNT_PAGE,
    SPLIT_BILLING_PAGE,
    USER_PAGE,
  ],
  DASHBOARD_NAVBAR: [
    UTILITY_ACCOUNT_PAGE,
    SPLIT_BILLING_PAGE,
    USER_PAGE,
  ],
};

export default ALL_ROUTES;
