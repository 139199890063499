import AuthEndpoints from './types/endpoints';
import { ID_REFRESH_TOKEN_KEY } from '../../jwt';
import ApiService from '../index';
import {
  AuthorizePassResetResponse, LoginResponse, RefreshTokensResponse, RegisterResponse,
} from './types/read';
import {
  AuthorizeForgotPassword,
  ConfirmForgotPassword,
  InitiateForgotPassword,
  LoginUser, ReactivateUser,
  RegisterConsumer,
  RegisterSupplierUser,
} from './types/command';

export default class AuthApiService {
  static async register(command: RegisterConsumer): Promise<RegisterResponse | undefined> {
    const response = await ApiService.post(AuthEndpoints.REGISTER_CONSUMER(), command);
    return response?.data;
  }

  static async registerSupplierUser(command: RegisterSupplierUser): Promise<RegisterResponse | undefined> {
    const response = await ApiService.post(AuthEndpoints.REGISTER_SUPPLIER(), command);
    return response?.data;
  }

  static async login(command: LoginUser): Promise<LoginResponse | undefined> {
    const response = await ApiService.post(
      AuthEndpoints.LOGIN(),
      command,
    );
    return response?.data;
  }

  static async refreshTokens(): Promise<RefreshTokensResponse | undefined> {
    ApiService.setHeaderFromJWT(ID_REFRESH_TOKEN_KEY);
    const response = await ApiService.post(AuthEndpoints.REFRESH());
    if (!response) {
      return;
    }
    return response?.data;
  }

  static async activateUser(token: string): Promise<LoginResponse | undefined> {
    const response = await ApiService.post(AuthEndpoints.ACTIVATE(), { token });
    return response?.data;
  }

  static async retryUserActivation(command: ReactivateUser): Promise<void> {
    await ApiService.post(AuthEndpoints.RETRY_ACTIVATE(), command);
  }

  static async authorizeForgotPassword(command: AuthorizeForgotPassword): Promise<AuthorizePassResetResponse | undefined> {
    const response = await ApiService.post(
      AuthEndpoints.AUTHORIZE_FORGOT_PASSWORD(),
      command,
    );
    return response?.data;
  }

  static async initiateForgotPassword(command: InitiateForgotPassword): Promise<void> {
    await ApiService.post(AuthEndpoints.INITIATE_FORGOT_PASSWORD(), command);
  }

  static async confirmForgotPassword(command: ConfirmForgotPassword): Promise<void> {
    await ApiService.post(
      AuthEndpoints.CONFIRM_FORGOT_PASSWORD(),
      command,
    );
  }
}
