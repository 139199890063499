import errorModule from '../../store/modules/error.module';
import authModule from '../../store/modules/auth.module';
import { APIErrorKind, ClientErrorKind } from './types/enum';

/**
 * Takes API error messages and returns a client-friendly error message.
 * @param errorMessage
 */
export default function prettifyErrorMessages(errorMessage?: string): string {
  if (!errorMessage) { return ClientErrorKind.Generic; }
  // Bad JWT?
  if (errorMessage.includes(APIErrorKind.ExpiredSignature) || errorMessage.includes(APIErrorKind.FailedAuth)) {
    return ClientErrorKind.ExpiredSignature;
  }

  // Utility account does not exist
  if (errorMessage.includes(APIErrorKind.UtilityAccountDoesNotExist)) {
    return ClientErrorKind.UtilityAccountDoesNotExist;
  }

  // User already registered.
  if (errorMessage.includes(APIErrorKind.AlreadyRegistered)) {
    return ClientErrorKind.AlreadyRegistered;
  }

  // User is inactive.
  if (errorMessage.includes(APIErrorKind.InactiveAccount)) {
    return ClientErrorKind.InactiveAccount;
  }

  // Supplier does not exist.
  if (errorMessage.includes(APIErrorKind.UserDoesNotExist) && errorMessage.includes(APIErrorKind.SupplierDoesNotExist)) {
    return ClientErrorKind.SupplierDoesNotExist;
  }

  // Failed to validate user's address.
  if (errorMessage.includes(APIErrorKind.AddressValidationFailed)) {
    return ClientErrorKind.AddressValidationFailed;
  }

  // Password is incorrect
  if (errorMessage.includes(APIErrorKind.IncorrectPassword)) {
    return ClientErrorKind.IncorrectPassword;
  }

  // Supplier does not exist
  if (errorMessage.match(APIErrorKind.SupplierDoesNotExist)) {
    return ClientErrorKind.SupplierDoesNotExist;
  }

  // User does not exist
  if (errorMessage.match(APIErrorKind.UserDoesNotExist)) {
    return ClientErrorKind.IncorrectPassword;
  }

  // Invalid reset pin given
  if (errorMessage.includes(APIErrorKind.InvalidResetPin)) {
    return ClientErrorKind.InvalidResetPin;
  }

  // Invalid reset pin given
  if (errorMessage.includes(APIErrorKind.TooManyResetFails)) {
    return ClientErrorKind.TooManyResetFails;
  }

  // Zip code is invalid (non-New England)
  if (errorMessage.includes(APIErrorKind.ZipCodeIsInvalid)) {
    return ClientErrorKind.ZipCodeIsInvalid;
  }

  if (errorMessage.includes(APIErrorKind.BadPermission)) {
    return ClientErrorKind.BadPermission;
  }

  if (errorMessage.includes(APIErrorKind.WillCallServiceAlreadyExists)) {
    return ClientErrorKind.WillCallServiceAlreadyExists;
  }

  return ClientErrorKind.Generic;
}

/**
 * Check if the JWT is expired and attempt to refresh the tokens if it is expired.
 * If the refresh attempt fails, send the user to the login screen.
 * @param errorMessage
 */
export async function isJwtExpired(errorMessage: string): Promise<boolean> {
  // Check if error message matches that of an expired signature.
  if (!errorMessage.includes(APIErrorKind.ExpiredSignature)
    && !errorMessage.includes(APIErrorKind.BadPermission)) {
    return false;
  }
  // If the signature is expired, attempt to refresh the tokens.
  await authModule.refreshJWT();
  if (errorModule.hasError) {
    errorModule.clearError();
    return false;
  }
  return true;
}
