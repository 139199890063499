import Cookies from 'js-cookie';

export const ID_ACCESS_TOKEN_KEY = 'A_JWT';
export const ID_REFRESH_TOKEN_KEY = 'R_JWT';
export const USER_ID_KEY = 'U_ID';

export default class JwtService {
  static getToken(tokenKey: string): string | undefined {
    return Cookies.get(tokenKey);
  }

  static checkIfTokenExists(tokenKey: string): boolean {
    return !!Cookies.get(tokenKey);
  }

  static saveToken(tokenKey: string, token: string): void {
    JwtService.destroyToken(tokenKey);
    Cookies.set(tokenKey, token);
  }

  static destroyToken(tokenKey: string): void {
    Cookies.remove(tokenKey);
  }

  static destroyAllTokens(): void {
    Cookies.remove(ID_REFRESH_TOKEN_KEY);
    Cookies.remove(ID_ACCESS_TOKEN_KEY);
    Cookies.remove(USER_ID_KEY);
  }
}
