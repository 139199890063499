<template>
  <div class="consumer-app">
    <dashboard-navbar />
    <router-view style="margin-top:138px;" />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import DashboardNavbar from '@/components/DashboardNavbar.vue';
import utilityaccountModule from '@/store/modules/utilityaccount.module';
import supplierModule from '@/store/modules/electricSupplier.module';

@Component({
  components: {
    DashboardNavbar,
  },
})
export default class DashboardRoot extends Vue {
  // Methods
  async created(): Promise<void> {
    await this.fetchTableData();
  }

  async fetchTableData(): Promise<void> {
    // eslint-disable-next-line no-restricted-syntax
    for (const utilityAccount of Object.values(utilityaccountModule.allUtilityAccounts)) {
      const [property] = utilityAccount.properties;
      if (!property) { continue; }
      const [meterRecord] = property.meter_records;
      const activeContract = meterRecord.contracts.active;
      if (!meterRecord || !activeContract) { continue; }
      const supplierId = activeContract.supplier_id;
      // If the supplier ID already exists in the supplier list, do not fetch it.
      if (supplierModule.suppliers[supplierId]) continue;
      // eslint-disable-next-line no-await-in-loop
      await supplierModule.fetchSupplier({ supplier_id: supplierId });
    }
  }
}
</script>

<style lang="less">
.portal-bg {
  margin-top: 40px;
}
</style>
