import {
  VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import { UtilityAccount, MeterRecord, UtilityAccountProperty } from '@/models/utilityAccount.model';
import {
  MarkReadingAsCompleted, MarkReadingOutOfSync,
  RegisterPendingContactSwitch,
  RegisterUtilityAccount,
  StopNotifications,
} from '@/services/api/utility_account/types/command';
import { FetchPropertyAverages, FetchUtilityAccount } from '@/services/api/utility_account/types/query';
import store from '../index';
import userModule from './user.module';
import UtilityAccountApiService from '../../services/api/utility_account';
import { UtilityAccountState } from '../states';

@Module({ dynamic: true, store, name: 'utility_account' })
class UtilityAccountModule extends VuexModule implements UtilityAccountState {
  utilityAccount: UtilityAccount | null = null;

  utilityAccountLoaded = false;

  activeProperty: UtilityAccountProperty | null = null;

  allUtilityAccounts: { [key: string]: UtilityAccount } = {};

  euaProperties: { [key: string]: UtilityAccountProperty } = {};

  meterRecords: { [key: string]: MeterRecord } = {};

  selectedMeterRecord: MeterRecord | null = null;

  activeProperties: UtilityAccountProperty[] = [];

  selectedPropertyAverages: Record<string, number> = {};

  splitBillUtilityAccounts: { [key: string]: UtilityAccount } = {};

  @Mutation
  SET_ALL_UTILITY_ACCOUNTS(accounts: UtilityAccount[]): void {
    accounts.forEach((ua) => {
      this.allUtilityAccounts[ua.id] = ua;
    });
  }

  @Mutation
  SET_ACTIVE_PROPERTY(property: UtilityAccountProperty): void {
    this.activeProperty = property;
  }

  @Mutation
  SET_METER_RECORDS(meterRecords: MeterRecord[]): void {
    meterRecords.forEach((mr) => {
      this.meterRecords[mr.meter.service_reference_number] = mr;
    });
  }

  @Mutation
  SET_PROPERTIES(properties: UtilityAccountProperty[]): void {
    properties.forEach((p) => {
      this.euaProperties[p.reference_id] = p;
    });
  }

  @Mutation
  SET_SELECTED_PROPERTY_AVERAGES(averages: Record<string, number>): void {
    this.selectedPropertyAverages = averages;
  }

  @Mutation
  SET_SELECTED_METER(meter: MeterRecord): void {
    this.selectedMeterRecord = meter;
  }

  @Mutation
  SET_UTILITY_ACCOUNT_LOADED(isLoaded: boolean): void {
    this.utilityAccountLoaded = isLoaded;
  }

  @Mutation
  SET_UTILITY_ACCOUNT(utilityAccount: UtilityAccount): void {
    this.utilityAccount = utilityAccount;
    this.allUtilityAccounts[utilityAccount.id] = utilityAccount;
  }

  @Mutation
  ADD_EUA_TO_SPLIT_BILL_EUAS(utilityAccounts: UtilityAccount): void {
    this.splitBillUtilityAccounts[utilityAccounts.id] = utilityAccounts;
  }

  @Action
  async fetchUtilityAccount(query: FetchUtilityAccount): Promise<void> {
    // If user is set, the user ID is safe to use.
    if (!userModule.userId) { return; }
    const response = await UtilityAccountApiService.fetchUtilityAccount(query);
    this.SET_UTILITY_ACCOUNT_LOADED(true);
    if (!response?.utilityaccount) { return; }
    const { utilityaccount: utilityAccount } = response;
    this.SET_UTILITY_ACCOUNT(utilityAccount);

    // Set the first property as the default selected.
    if (utilityAccount.properties.length === 0) { return; }
    const selectedProperty = utilityAccount.properties[0];
    this.SET_PROPERTIES(utilityAccount.properties);
    this.SET_ACTIVE_PROPERTY(selectedProperty);

    // Set the first meter contract as the default selected.
    if (selectedProperty.meter_records.length === 0) { return; }
    this.SET_METER_RECORDS(selectedProperty.meter_records);
    const selectedMeterRecord = selectedProperty.meter_records[0];
    this.SET_SELECTED_METER(selectedMeterRecord);
  }

  @Action
  async updateSelectedProperty(selectedProperty: UtilityAccountProperty): Promise<void> {
    this.SET_ACTIVE_PROPERTY(selectedProperty);
    // Set the first meter contract as the default selected.
    if (selectedProperty.meter_records.length === 0) { return; }
    const selectedMeterRecord = selectedProperty.meter_records[0];
    this.SET_SELECTED_METER(selectedMeterRecord);
    if (!this.utilityAccount) { return; }
    const response = await UtilityAccountApiService.fetchAverageUsages({
      utility_account_id: this.utilityAccount.id,
      property_id: selectedProperty.reference_id,
    });
    if (!response) { return; }
    this.SET_SELECTED_PROPERTY_AVERAGES(response.averages);
  }

  @Action
  async stopExpirationReminders(command: StopNotifications): Promise<void> {
    await UtilityAccountApiService.stopExpirationReminders(command);
  }

  @Action
  async registerUtilityAccount(command: RegisterUtilityAccount): Promise<void> {
    await UtilityAccountApiService.registerUtilityAccount(command);
  }

  @Action
  async fetchAverageUsagesByMonth(query: FetchPropertyAverages): Promise<void> {
    const response = await UtilityAccountApiService.fetchAverageUsages(query);
    if (!response) { return; }
    this.SET_SELECTED_PROPERTY_AVERAGES(response.averages);
  }

  @Action
  async fetchAllUtilityAccounts(): Promise<void> {
    const response = await UtilityAccountApiService.fetchAllUtilityAccounts();
    if (!response) { return; }
    this.SET_ALL_UTILITY_ACCOUNTS(response.utilityaccounts);
    response.utilityaccounts.forEach((ua) => {
      this.SET_PROPERTIES(ua.properties);
      ua.properties.forEach((p) => {
        this.SET_METER_RECORDS(p.meter_records);
      });
    });
  }

  @Action
  updateUtilityAccountsWithSplitBilling(utilityAccount: UtilityAccount): void {
    this.ADD_EUA_TO_SPLIT_BILL_EUAS(utilityAccount);
  }

  @Action
  async registerPendingContractSwitch(command: RegisterPendingContactSwitch): Promise<void> {
    await UtilityAccountApiService.registerPendingContractSwitch(command);
  }

  @Action
  async markReadingAsCompleted(command: MarkReadingAsCompleted): Promise<void> {
    await UtilityAccountApiService.markReadingAsCompleted(command);
  }

  @Action
  async markReadingOutOfSync(command: MarkReadingOutOfSync): Promise<void> {
    await UtilityAccountApiService.markReadingOutOfSync(command);
  }
}

export default getModule(UtilityAccountModule);
