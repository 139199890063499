const UtilityEndpoint = {
  // Used for signing up and when public user is searching for plans,
  //  determines their utility from which we could fetch relevant offerings
  FETCH_UTILITY: (zipCode: string): string => `/electric_utilities/zipcode/${zipCode}`,

  FETCH_DISTRIBUTION_RATE: (utilityId: string): string => `/electric_utilities/${utilityId}/estimate_distribution_cost`,

  FETCH_ALL_UTILITIES: (): string => '/electric_utilities',
};

export default UtilityEndpoint;
