import Vue from 'vue';
import Router, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import errorModule from '@/store/modules/error.module';
import PAGES, { LOGIN_PAGE } from '@/router/routes';
import { PRODUCT_NAME } from '@/config/business.config';

// Pages that are common among both users signed in and public
import CommonNotFound from '@/pages/common/NotFound.vue';

// Pages within app where user has to be authenticated to view
import DashboardRoot from '@/root/DashboardRoot.vue';
import PublicRoot from '@/root/PublicRoot.vue';
import userModule from '@/store/modules/user.module';
import authModule from '@/store/modules/auth.module';
import { eventBus } from '@/main';
import { SHOW_SNACKBAR } from '@/config/eventNames';

const DEFAULT_TITLE = 'Loading...';
const TITLE_SUFFIX = ` | ${PRODUCT_NAME}`;

const router = new Router({ mode: 'history', routes: [] });

export function navigateTo(route: RouteConfig): void {
  router.push(route).catch((error) => {
    // Ignore NavigationDuplicated error, but log the rest.
    if (Router.isNavigationFailure(error)) {
      return;
    }
    // eslint-disable-next-line no-console
    console.error(error);
  });
}

router.addRoutes([
  // Publicly accessible routes
  {
    path: '/',
    component: PublicRoot,
    children: PAGES.PUBLIC,
  },

  // Routes accessible by logged in consumers
  {
    path: '/dashboard',
    component: DashboardRoot,
    children: PAGES.APP,
    async beforeEnter(_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> {
      await userModule.loadUser();
      if (!userModule.user?.role) {
        navigateTo(LOGIN_PAGE);
        return;
      }
      if (userModule.user?.role.discriminant === 'Consumer' || userModule.user?.role.discriminant === 'SupplierEmployee') {
        eventBus.emit(SHOW_SNACKBAR, `${userModule.user?.role.discriminant} users are not allowed to access this portal.`);
        return;
      }
      await Promise.all([
        userModule.fetchAllUsers(),
        // supplierModule.fetchAllSuppliers(),
      ]);
      errorModule.clearError();
      next();
    },
  },
  // Any undefined route will be redirected to the 404 page
  {
    path: '*',
    component: CommonNotFound,
    meta: {
      title: '404',
    },
  },
]);

router.beforeEach((to, from, next) => {
  if (to && to.meta) {
    document.title = to.meta.title + TITLE_SUFFIX || DEFAULT_TITLE;
  }
  // Stop the loading UI from being displayed if someone navigates away.
  errorModule.setLoading(false);
  window.scrollTo(0, 0);
  // If user is navigating to the same path as they are currently on, don't continue.
  if (to.name === from.name) return;
  next();
  // If the app's state is over 24 hours old, refresh the page to pull the latest changes.
  const currentDate = new Date();
  if (currentDate.getUTCMinutes() - authModule.lastUpdatedTime.getUTCMinutes() > 1440) {
    window.location.reload();
  }
});

Vue.use(Router);

export default router;
