import { InviteSupplierUser, RegisterAdmin } from '@/services/api/electric_supplier/types/command';
import ApiService from '../index';
import UserEndpoint from './types/endpoints';
import {
  ChangeUserEmail,
  ChangeUserName,
  ChangeUserPassword,
  ConfirmPaymentIntent,
  CreatePaymentIntent, CreateSetupIntent, GenerateReferralToken,
  SubscribeUser,
  UnsubscribeUser,
} from './types/command';
import {
  FetchAllUsersResponse,
  FetchPaymentIntentResponse,
  FetchSetupIntentResponse,
  FetchUserResponse,
  GenerateReferralTokenResponse,
} from './types/read';
import { FetchUser } from './types/query';

export default class UserApiService {
  static async subscribe(command: SubscribeUser): Promise<boolean> {
    const response = await ApiService.post(
      UserEndpoint.SUBSCRIBE(command.user_id),
      command,
    );
    return !!response;
  }

  static async unsubscribeUser(command: UnsubscribeUser): Promise<boolean> {
    const response = await ApiService.post(
      UserEndpoint.UNSUBSCRIBE(
        command.user_id,
      ),
    );
    return !!response;
  }

  static async fetchPaymentIntent(command: CreatePaymentIntent): Promise<FetchPaymentIntentResponse | undefined> {
    const response = await ApiService.post(
      UserEndpoint.FETCH_PAYMENT_INTENT(command.user_id),
      command,
    );
    return response?.data;
  }

  static async fetchSetupIntent(command: CreateSetupIntent):
    Promise<FetchSetupIntentResponse | undefined> {
    const response = await ApiService.post(
      UserEndpoint.FETCH_SETUP_INTENT(command.user_id),
    );
    return response?.data;
  }

  static async loadUser(query: FetchUser): Promise<FetchUserResponse | undefined> {
    const response = await ApiService.get(
      UserEndpoint.FETCH_USER(query.user_id),
    );
    return response?.data;
  }

  static async changeUserPassword(command: ChangeUserPassword): Promise<boolean> {
    const response = await ApiService.post(
      UserEndpoint.CHANGE_PASSWORD(command.user_id),
      command,
    );
    return !!response;
  }

  static async changeUserFullName(command: ChangeUserName): Promise<boolean> {
    const response = await ApiService.post(
      UserEndpoint.CHANGE_NAME(command.user_id),
      command,
    );
    return !!response;
  }

  static async changeUserEmail(command: ChangeUserEmail): Promise<boolean> {
    const response = await ApiService.post(
      UserEndpoint.CHANGE_EMAIL(command.user_id),
      command,
    );
    return !!response;
  }

  static async generateReferralToken(command: GenerateReferralToken): Promise<GenerateReferralTokenResponse | undefined> {
    const response = await ApiService.post(
      UserEndpoint.GENERATE_REFERRAL_TOKEN(command.user_id),
    );
    return response?.data;
  }

  static async confirmPaymentIntent(command: ConfirmPaymentIntent): Promise<boolean> {
    const response = await ApiService.post(
      UserEndpoint.CONFIRM_PAYMENT_INTENT(command.user_id),
      command.confirmation_secret,
    );
    return !!response;
  }

  static async fetchAllUsers(): Promise<FetchAllUsersResponse> {
    const response = await ApiService.get(UserEndpoint.FETCH_ALL());
    return response?.data;
  }

  static async inviteSupplierUser(command: InviteSupplierUser): Promise<void> {
    await ApiService.post(
      UserEndpoint.INVITE_SUPPLIER_USER(),
      command,
    );
  }

  static async registerAdmin(command: RegisterAdmin): Promise<void> {
    await ApiService.post(
      UserEndpoint.REGISTER_ADMIN(),
      command,
    );
  }
}
