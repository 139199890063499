import {
  VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import store from '../index';
import { ErrorState } from '../states';

@Module({ dynamic: true, store, name: 'error' })
class ErrorModule extends VuexModule implements ErrorState {
  message = '';

  hasError = false;

  isLoading = false;

  isDownForMaintenance = false;

  isGoldEnabled = true;

  @Mutation
  SET_ERROR(error: string): void {
    this.message = error;
    this.hasError = true;
  }

  @Mutation
  SET_MAINTENANCE_MODE(status: boolean): void {
    this.isDownForMaintenance = status;
  }

  @Mutation
  SET_LOADING(loadingStatus: boolean): void {
    this.isLoading = loadingStatus;
  }

  @Mutation
  PURGE_ERROR(): void {
    this.message = '';
    this.hasError = false;
  }

  @Action
  throwError(error: string): void {
    this.SET_ERROR(error);
  }

  /**
   * When the site is undergoing maintenance and the API is out of service,
   * an overlay informs the user of the status. This overlay will be removed
   * once they receive an API response not containing a 503 or maintenance status.
   * Users still have access the navbar and footer during this period.
   * @param modeStatus
   */
  @Action
  setMaintenanceMode(modeStatus = true): void {
    this.SET_MAINTENANCE_MODE(modeStatus);
  }

  @Action
  clearError(): void {
    this.PURGE_ERROR();
  }

  @Action
  setLoading(loadingStatus: boolean): void {
    this.SET_LOADING(loadingStatus);
  }
}

export default getModule(ErrorModule);
