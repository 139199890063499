const SupplierEndpoint = {
  FETCH_OFFERINGS: (): string => '/electric_suppliers',

  FETCH_RECOMMENDED_OFFERINGS: (): string => '/electric_suppliers/by_jurisdiction/then_sort_by_estimated_cost',

  FETCH_SUPPLIER: (supplierId: string): string => `/electric_suppliers/${supplierId}`,

};

export default SupplierEndpoint;
