/* eslint-disable max-len */
import { FaqItem } from '../models/ui.model';

export const PRODUCT_NAME = 'GridLite';
export const MONTHLY_PRICE = '3.99';
export const ANNUAL_PRICE = '39.99';
export const PARENT_COMPANY = 'Lunabit Technologies LLC';

export const BLOG_PAGE = 'https://blog.lunabit.io/category/gridlite/';

export const DEFAULT_USAGE = 700;
export const DEFAULT_DISTRO_RATE = '005';

export const FAQS: FaqItem[] = [
  { question: `What does ${PRODUCT_NAME} do?`, answer: `${PRODUCT_NAME} notifies you before your electric contract expires, visualizes your monthly electric usage, and recommends you the best offerings for your home. Want to shop for yourself? You can browse through all available electric offerings on the ${PRODUCT_NAME} search page.` },
  { question: `How much does ${PRODUCT_NAME} cost?`, answer: `${PRODUCT_NAME} offers contract monitoring, recommendations, and expiration reminders for <span class="font-weight-bold">free.</span>` },
  { question: `Is ${PRODUCT_NAME} an energy supplier?`, answer: `No, we are not an energy supplier or broker. ${PRODUCT_NAME} is an independent service that curates offerings from electric suppliers and brokers. We make these offerings searchable for our users and help them choose the best plan for their homes.` },
  { question: `Do I pay my electric bill through ${PRODUCT_NAME}?`, answer: `No, ${PRODUCT_NAME} is not involved in any step in the bill payment process. ${PRODUCT_NAME} monitors your electric contract and helps you find the best electric contract for your home.` },
  { question: `Can ${PRODUCT_NAME} switch my plan for me?`, answer: `Currently, ${PRODUCT_NAME} does not support automatic contract switching.` },
  { question: 'Do different energy suppliers/brokers handle outages or issues with delivery differently?', answer: 'No. Regardless of the energy supplier you switch to, your utility is ultimately responsible for the power lines.' },
  { question: 'Would changing my supplier affect my distribution rate?', answer: 'No, your utility determines your meter\'s distribution rate. Changing your supplier will have no impact on your distribution rate.' },
  { question: `Does ${PRODUCT_NAME} support commercial properties?`, answer: `Currently, ${PRODUCT_NAME} only supports residential properties.` },
  /* { question: `Can I cancel after subscribing to ${PRODUCT_NAME} Gold?`, answer: `While we'll be sad to see you go, we understand that some customers would like to manage their contact on their own. You could cancel the service at any time, and we've made the first month free to give you an opportunity to try ${PRODUCT_NAME} Gold out for yourself.` }, */
];

export const TOS = `This site is offered to you conditioned on your acceptance without modification of the terms, conditions and notices contained herein. Your use of this site constitutes your agreement to all such terms, conditions and notices. This service is powered and operated by ${PARENT_COMPANY} (“Lunabit”, "we" or "us"). You must be 18 years of age or older to use this site.

${PRODUCT_NAME} allows subscribers to monitor, evaluate, and enroll in energy services through third party providers. By using the Service, you accept the terms of this Agreement. This is a legally binding agreement between you and Lunabit.
${PRODUCT_NAME} operates solely as a service provider by providing prospective buyers access to pre-screened energy offers that meet certain proprietary conditions. You are not permitted to use the Service unless you have successfully subscribed to be a member of the Service ("Member").
The Service is available to Members to assist in the purchase of energy. Additionally, optional related services may be purchased. The price and other terms remain subject to service agreements between the buying Member and the third party provider. ${PRODUCT_NAME} cannot guarantee the rates and/or services provided by the third party vendor.
${PRODUCT_NAME} has the right to cancel or suspend a Member's account at any time and without notice upon a breach, violation or non-compliance of this Agreement or any other policies, terms of use, or agreements of ${PRODUCT_NAME}. Notwithstanding the foregoing, under no circumstances shall ${PRODUCT_NAME} be required to issue refunds hereunder.
${PRODUCT_NAME} is the provider of the Service and is not a party to any contract concluded pursuant to the Service. ${PRODUCT_NAME} is not responsible or liable to any Member, or any other user of the Service in any way, including, without limitation, any disputes arising out of the contract between Members and third party energy service providers, such as disputes related whether the parties have fully performed their obligations thereunder and any disputes related to whether a contract has been validly entered into or concluded.
Each energy transaction shall be consummated directly between the member and third party provider, and ${PRODUCT_NAME} has no interest, as broker or otherwise, in such transaction.

COPYRIGHT AND TRADEMARK NOTICES
The materials, content, data and information provided to or made available through the Service are the property of ${PRODUCT_NAME} or its licensors, and are protected by U.S. copyright laws, other copyright laws, and international conventions. Except as explicitly provided for in this Agreement, you may not distribute, transmit, display, reproduce, modify, create derivative works from, or otherwise exploit any of the materials available through the Service.
${PRODUCT_NAME} and the names of other products and services referred to through the Service are the trademarks of ${PARENT_COMPANY} and their respective owners. You may not use any trademark or service mark appearing through the Service without the prior written consent of the owner of the mark.


FEES
The use of the ${PRODUCT_NAME} site requires an ${PRODUCT_NAME} service subscription, a separate service subscription is required for each meter.
Some notes about ${PRODUCT_NAME} service subscriptions:
Until you cancel a subscription by going to the subscription page of the app, choosing “Modify Subscription”, and pressing “Unsubscribe”, it will automatically renew at the end of each subscription term at the same fee (even if you are not using the ${PRODUCT_NAME} portal).
Monthly ${PRODUCT_NAME} service subscriptions may not be transferred to another meter or subscriber.
Although we strive to make the ${PRODUCT_NAME} service available as much as possible, there may be periods of downtime (for example, for scheduled maintenance, software updates, or events outside of our reasonable control).

MULTI-SERVICE DISCOUNT
In some cases, we offer discounted ${PRODUCT_NAME} service fees when you have more than one meter on the same account. Contact customer service at hello@lunabit.io for more information.
Some restrictions that apply to the multi-service discount:


PAYMENT TERMS
When you provide credit card information to us, you represent that you are the authorized user of the card, and agree to notify us of any changes to the relevant account number, expiration date, and billing address, or if the card expires or is canceled. You agree that we may receive updated credit card information (such as a new expiration date) from your credit card issuer.
We will charge your credit card on each billing date for your ${PRODUCT_NAME} service subscription. You are responsible for reviewing your credit card statement for billing accuracy.
Unless notified by ${PRODUCT_NAME}, your service subscription will automatically renew at the end of each subscription term at the same fee, and you authorize us to automatically charge the subscription fee to your credit card each billing date until you cancel your subscription 30 days prior to auto-renewal. Subscription fees paid prior to cancellation are nonrefundable.
Some notes about payments:
By subscribing to the ${PRODUCT_NAME} service, you authorize us to charge the applicable subscription fees (and any other applicable fees, such as an early termination fee) to your credit card on file.
If your credit card reaches its expiration date and you have not updated your payment information with us or cancelled your account, you authorize us to continue billing your credit card and you will remain responsible for any uncollected amounts.
Each charge on the applicable billing date applies to the subscription period immediately following the billing date (so a charge for a monthly subscription in January is for the ${PRODUCT_NAME} service in the month of February).
All prices on ${PRODUCT_NAME} are listed in U.S. Dollars.
We reserve the right to increase the monthly subscription fee for ${PRODUCT_NAME} service subscribers paying month-to-month with no commitment, but we will notify you 30 days in advance. If you do not accept the new fees, you should terminate your subscription before the new fees go into effect.
We reserve the right to suspend or terminate your subscription to the service without notice if your credit card is rejected or if your card issuer seeks the return of payments previously made to us. You agree to pay (1) any outstanding balance in full within 30 days of cancellation of your subscription, and (2) a late charge on all amounts more than 30 days past due. Such rights are in addition to and not in lieu of any other legal right or remedies available to ${PRODUCT_NAME}.
We also reserve the right to refer your account to a third party for collection to pursue unpaid amounts, and you will remain liable to us for all unpaid charges and all the costs we incur to collect those charges (such as collection agency fees).

SERVICES
General
You agree that no joint venture, partnership, employment or agency relationship exists between you and us as a result of this agreement or use of this site. Nothing contained in this site shall be construed to prevent us from complying with law enforcement requests or requirements relating to your use of this site or information provided to or gathered by us with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision, and the remainder of the agreement shall continue in effect. This agreement constitutes the entire agreement between you and us with respect to your use of this site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. Fictitious names of companies, products, people, characters and/or data mentioned herein are not intended to represent any real individual, company, product, or event. Any rights not expressly granted herein are reserved.


Use of Our Services
Whenever you order or purchase a service or product through this site you are warranting to us and the applicable service provider that you are at least 18 years of age and possess the legal authority to enter into the transactions made available through this site and will use this service in accordance with all terms and conditions herein and as set forth in this site. You agree to be financially responsible for your use of and all transactions made in connection with services and products ordered or purchased through this site. Without limitation, any speculative, false or fraudulent purchase, order, reservation or notification to any service provider through this site is prohibited. You agree that you will be completely responsible for all charges, fees, duties, taxes and assessments arising out of the purchases and orders you make through this site. Crawling the Services is allowed if done in accordance with the provisions of our robots.txt file, but scraping the Services is strictly prohibited. By using ${PRODUCT_NAME}, you give us permission to view and store your utility account data, including energy contract, energy usage, and other account information. 

Liability Disclaimer
IF YOU RELY ON THE SERVICE, OR ANY INFORMATION, PRODUCT OR SERVICE AVAILABLE THROUGH THE SERVICE, YOU DO SO AT YOUR OWN RISK. YOU UNDERSTAND THAT THERE MAY BE DELAYS, OMISSIONS, INTERRUPTIONS, INACCURACIES, AND/OR OTHER PROBLEMS WITH THE INFORMATION, PRODUCTS, AND SERVICES PUBLISHED ON OR PROMOTED OVER THE SERVICE. THE SERVICE AND THE WEBSITE ARE PROVIDED TO YOU "AS IS." ${PRODUCT_NAME} AND ITS AFFILIATES, AGENTS AND LICENSORS, CANNOT AND DO NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE INFORMATION AVAILABLE THROUGH THE SERVICE (OR ANY INFORMATION, GOODS OR SERVICES THAT ARE REFERRED TO, ADVERTISED OR PROMOTED ON, OR SOLD THROUGH THE SERVICE). NOR DOES ${PRODUCT_NAME}, ITS AFFILIATES, AGENTS AND LICENSORS GUARANTEE THAT THE SERVICE WILL BE ERROR FREE, OR CONTINUOUSLY AVAILABLE, OR THAT THE SERVICE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
UNDER NO CIRCUMSTANCES WILL ${PRODUCT_NAME} OR ITS AFFILIATES, AGENTS OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES, COSTS, EXPENSES, FEES OR LIABILITIES ARISING OUT OF YOUR USE OF THE SERVICE OR ANY PRODUCT OR SERVICE LINKED TO FROM OR ADVERTISED OR PROMOTED ON THE SERVICE, INCLUDING, WITHOUT LIMITATION, DIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, OR OTHER DAMAGES OF ANY KIND (INCLUDING LOST REVENUES OR PROFITS, LOSS OF BUSINESS OR LOSS OF DATA), EVEN IF ${PRODUCT_NAME} IS ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SOME OF THESE LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH CASES, ${PRODUCT_NAME}'S LIABILITY IS LIMITED TO THE MEMBERSHIP FEES YOU PAID DURING THE PERIOD OF YOU ENERGY SERVICES CONTRACT. YOU AGREE TO INDEMNIFY AND HOLD HARMLESS ${PRODUCT_NAME}, ITS EMPLOYEES, OWNERS, REPRESENTATIVES, AND LICENSEES AGAINST ANY AND ALL CLAIMS, OF WHATEVER NATURE, THAT ARISE OUT OF YOUR USE OF THE SERVICE.


No Unlawful or Prohibited Use
As a condition of your use of this site, you warrant that you will not use this site for any purpose that is unlawful or prohibited by these terms, conditions and notices.

Referral Program
${PRODUCT_NAME} reserves the right to withhold or revoke any referral reward including by not limited to gift cards for any reason, including by not limited to suspicion of fraud or ending a referral marketing campaign. 

Energy Service Providers
This site does not represent or imply that any service category available on this site contains a complete or comprehensive list or choice of all service providers that can provide service to you at your particular address. This site may exclude certain service providers that do not meet ${PRODUCT_NAME}'s proprietary standards. Accordingly, there may be service providers that can provide you with service at your particular address that do not appear on this site and are not listed as a choice for you to select under a particular service category contained on this site. You are in no way obligated to use this site or to choose any particular service provider that appears on this site. Each Member authorizes ${PRODUCT_NAME} to request and/or retrieve information related to your electricity usage. ${PRODUCT_NAME} will establish accounts, on your behalf, in order to retrieve this information when necessary. These accounts may be established with your Transmission and Distribution Service Provider ("TDSP") or with Smart Meter Texas ("SMT"). If established, your credentials will be made available to you at your request. Member agrees to abide by the SMT Terms and Conditions whenever an account is established on their behalf. Each Member agrees and understands that he or she is appointing ${PRODUCT_NAME} as his or her limited agent for purposes of selecting and enrolling the member's service location for electricity service with Retail Electricity providers from time to time on their behalf. BY USING THIS SITE EACH MEMBER ACKNOWLEDGES AND AGREES THAT HE OR SHE IS AUTHORIZING GRIDLITE TO ENROLL THE MEMBER'S SERVICE LOCATION DIRECTLY WITH RETAIL ELECTRICITY PROVIDERS ON THEIR BEHALF. In addition to enrollments for service, each member acknowledges that ${PRODUCT_NAME} may call a Retail Electricity Provider on their behalf, in reference to their account.
`;
