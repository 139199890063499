import {
  VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import { ElectricOffering, ElectricSupplier } from '@/models/electric_supplier.model';
import { SortName } from '@/models/enum';
import { FetchSupplierById, FetchSuppliers } from '@/services/api/electric_supplier/types/query';
import ElectricSupplierApiService from '../../services/api/electric_supplier';
import store from '../index';
import { ElectricSupplierState } from '../states';

@Module({ dynamic: true, store, name: 'electric_supplier' })
class ElectricSupplierModule extends VuexModule implements ElectricSupplierState {
  availableOfferings: ElectricOffering[] = [];

  suppliers: { [key: string]: ElectricSupplier } = {};

  selectedSupplier: ElectricSupplier | null = null;

  orderedOfferings: ElectricOffering[] = [];

  recommendedOfferingList: ElectricOffering[] = [];

  bestEconomicOffering: ElectricOffering | null = null;

  bestRenewableOffering: ElectricOffering | null = null;

  currentSortDescription = SortName.Savings;

  @Mutation
  SET_OFFERINGS(offerings: ElectricOffering[]): void {
    this.availableOfferings = offerings;
    this.orderedOfferings = offerings;
  }

  @Mutation
  SET_ORDERED_OFFERING(offerings: ElectricOffering[]): void {
    this.orderedOfferings = [...offerings];
  }

  @Mutation
  SET_SORT_DESCRIPTION(description: SortName): void {
    this.currentSortDescription = description;
  }

  @Mutation
  SET_RECOMMENDED_OFFERINGS(offerings: ElectricOffering[]): void {
    this.recommendedOfferingList = offerings;
    this.orderedOfferings = offerings;
  }

  @Mutation
  SET_BEST_ECONOMIC_OFFERING(offering: ElectricOffering): void {
    this.bestEconomicOffering = offering;
  }

  @Mutation
  SET_BEST_RENEWABLE_OFFERING(offering: ElectricOffering): void {
    this.bestRenewableOffering = offering;
  }

  @Mutation
  SET_SUPPLIERS(suppliers: ElectricSupplier[]): void {
    suppliers.forEach((s: ElectricSupplier) => {
      this.suppliers[s.id] = s;
    });
  }

  @Mutation
  ADD_SUPPLIER_TO_SUPPLIERS(supplier: ElectricSupplier): void {
    this.suppliers[supplier.id] = supplier;
  }

  @Mutation
  SET_SELECTED_SUPPLIER(supplier: ElectricSupplier): void {
    this.selectedSupplier = supplier;
  }

  @Action
  async fetchSupplier(query: FetchSupplierById): Promise<void> {
    const response = await ElectricSupplierApiService.fetchSupplier(query);
    if (!response) {
      return;
    }
    this.SET_SELECTED_SUPPLIER(response.supplier);
    this.ADD_SUPPLIER_TO_SUPPLIERS(response.supplier);
  }

  @Action
  async fetchOfferingsByUtilityId(query: FetchSuppliers): Promise<void> {
    // { utilityId: string, estimatedUsage?: number, kwhAverageUsages?: Record<string, number>, recaptchaToken?: string }
    const response = await ElectricSupplierApiService.fetchSuppliers(query);
    if (!response) return;
    const { offerings } = response;

    // Add simple IDs to the offerings for virtual list purposes.
    const [bestEconomicOffering] = offerings;
    this.SET_RECOMMENDED_OFFERINGS(offerings);
    this.SET_BEST_ECONOMIC_OFFERING(bestEconomicOffering);
    this.SET_OFFERINGS(offerings);

    // Set the lowest priced 100% renewable offering as the best renewable.
    offerings.some((offering: ElectricOffering) => {
      if (offering.percentage_renewable !== 100) {
        return;
      }
      this.SET_BEST_RENEWABLE_OFFERING(offering);
      return true;
    });
  }

  @Action
  updateOrderedOfferings(orderedOfferings: ElectricOffering[]): void {
    this.SET_ORDERED_OFFERING(orderedOfferings);
  }

  @Action
  purgeOfferings(): void {
    this.SET_OFFERINGS([]);
    this.SET_ORDERED_OFFERING([]);
  }

  @Action
  updateSearchDescription(description: SortName): void {
    this.SET_SORT_DESCRIPTION(description);
  }
}

export default getModule(ElectricSupplierModule);
