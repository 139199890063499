import { AxiosResponse } from 'axios';
import ApiService from '../index';
import PropertyEndpoint from './types/endpoints';
import { ArchiveProperty, RegisterProperty } from './types/command';
import { FetchProperty, FetchPropertyByOwner } from './types/query';
import { FetchPropertyResponse } from './types/read';

export default class PropertyApiService {
  static async createProperty(command: RegisterProperty): Promise<AxiosResponse | undefined> {
    const response = await ApiService.post(PropertyEndpoint.CREATE_PROPERTY(), command);
    return response;
  }

  static async fetchPropertyByOwnerId(query: FetchPropertyByOwner): Promise<FetchPropertyResponse | undefined> {
    const response = await ApiService.get(
      PropertyEndpoint.FETCH_PROPERTY_BY_OWNER_ID(),
      query,
    );
    return response?.data;
  }

  static async fetchProperty(query: FetchProperty): Promise<FetchPropertyResponse | undefined> {
    const response = await ApiService.get(
      PropertyEndpoint.FETCH_PROPERTY(query.property_id),
      {},
      false,
    );
    return response?.data;
  }

  static async archiveProperty(command: ArchiveProperty): Promise<void> {
    await ApiService.post(
      PropertyEndpoint.ARCHIVE_PROPERTY(command.property_id),
    );
  }
}
