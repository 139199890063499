<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      fixed
      height="90px" dark
      fade-img-on-scroll
    >
      <template #img="{ props }">
        <v-img v-bind="props" style="background-color: #2ecc71" />
      </template>

      <v-flex class="pl-6 pt-2">
        <h1 class="font-weight-black display-1 headers" v-text="'GridLite'" />
        <h4 class="font-weight-bold ml-1 headers" v-text="'Lunabit Technologies LLC'" />
      </v-flex>

      <v-spacer />

      <v-menu bottom left>
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs" v-on="on"
          >
            <v-icon color="#22313f">
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(button, i) in settingButtons" :key="i">
            <v-btn
              text class="headers text-none"
              color="black"
              @click="buttonClicked(button)"
            >
              {{
                button.title
              }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <template #extension>
        <v-tabs
          v-model="tab" align-with-title
          @change="selectedTabChanged"
        >
          <v-tab
            v-for="(route, index) in ADMIN_ROUTES" :key="index"
            class="headers text-none black--text font-weight-bold"
          >
            {{
              route.meta.title
            }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
  </v-card>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import ALL_ROUTES, { LOGIN_PAGE } from '@/router/routes';
import { eventBus } from '@/main';
import authModule from '@/store/modules/auth.module';
import { SHOW_SNACKBAR } from '@/config/eventNames';

interface NavbarButton {
  title: string;
  event: () => void;
}

@Component({
  components: {},
})
export default class AdminNavbar extends Vue {
  ADMIN_ROUTES = ALL_ROUTES.DASHBOARD_NAVBAR;

  tab = 0;

  settingButtons: Array<NavbarButton> = [{ title: 'Logout', event: this.logout }];

  async logout(): Promise<void> {
    eventBus.emit(SHOW_SNACKBAR, 'Logging out.');
    await authModule.logout();
    await this.$router.push(LOGIN_PAGE);
  }

  selectedTabChanged(): void {
    console.log('selectedTabChanged');
    this.$router.push(this.ADMIN_ROUTES[this.tab]);
  }

  buttonClicked(button: NavbarButton): void {
    button.event();
  }
}
</script>

<style scoped lang="less">
.subbrand-select {
  max-width: 200px;
  margin-top: 15px !important;
}
.headers {
  color: #22313f;
  font-size: 1rem;
}
</style>
