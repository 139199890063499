import {
  VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import { UserRole, UserAccount } from '@/models/user.model';
import { ANNUAL_PRICE, PRODUCT_NAME } from '@/config/business.config';
import {
  ChangeUserEmail,
  ChangeUserName,
  ChangeUserPassword, ConfirmPaymentIntent, CreatePaymentIntent, CreateSetupIntent, GenerateReferralToken,
  SubscribeUser, UnsubscribeUser,
} from '@/services/api/user/types/command';
import { FetchUser } from '@/services/api/user/types/query';
import { InviteSupplierUser, RegisterAdmin } from '@/services/api/electric_supplier/types/command';
import store from '../index';
import UserApiService from '../../services/api/user';
import JwtService, { ID_ACCESS_TOKEN_KEY, USER_ID_KEY } from '../../services/jwt';
import authModule from './auth.module';
import ApiService from '../../services/api';
import { UserState } from '../states';

@Module({ dynamic: true, store, name: 'user' })
class UserModule extends VuexModule implements UserState {
  userId = `${JwtService.getToken(USER_ID_KEY) || ''}`;

  isSubscribedToGold = false;

  role: UserRole | null = null;

  user: UserAccount | null = null;

  users: { [key: string]: UserAccount } = {};

  subscriptionAmount = ANNUAL_PRICE;

  subscriptionAmountDescription = `${PRODUCT_NAME} Gold (annual)`;

  referralToken = '';

  stripeClientSecret = '';

  @Mutation
  SET_USER_SUBSCRIPTIONS(payload: boolean): void {
    this.isSubscribedToGold = payload;
  }

  @Mutation
  SET_ALL_USERS(users: UserAccount[]): void {
    users.forEach((u) => {
      this.users[u.id] = u;
    });
  }

  @Mutation
  SET_USER(user: UserAccount): void {
    this.user = user;
    this.role = user.role;
  }

  @Mutation
  ADD_USER_TO_USERS(user: UserAccount): void {
    this.users[user.id] = user;
  }

  @Mutation
  SET_SUBSCRIPTION_INFO(payload: { amount: string, description: string }): void {
    this.subscriptionAmount = payload.amount;
    this.subscriptionAmountDescription = payload.description;
  }

  @Mutation
  SET_USER_NAME(fullName: string): void {
    if (!this.user) { return; }
    this.user.name = fullName;
  }

  @Mutation
  SET_USER_ID(userId: string): void {
    this.userId = userId;
    JwtService.saveToken(USER_ID_KEY, userId);
  }

  @Mutation
  SET_STRIPE_CLIENT_SECRET(clientSecret: string): void {
    this.stripeClientSecret = clientSecret;
  }

  @Mutation
  SET_REFERRAL_TOKEN(referralToken: string): void {
    this.referralToken = referralToken;
  }

  @Action({ rawError: true })
  async loadUser(): Promise<void> {
    ApiService.setHeaderFromJWT();
    // In case the load is off a refresh, and the state is not set,
    // we must pull the userId from the token if it exists
    const accessToken = JwtService.getToken(ID_ACCESS_TOKEN_KEY);
    const userId = JwtService.getToken(USER_ID_KEY);
    // Check if the there is a JWT set, if there isn't do not proceed.
    if (!accessToken || !userId) { authModule.logout(); return; }
    const response = await UserApiService.loadUser({
      user_id: userId,
    });
    // If there was an error loading the user, send to login
    if (!response) { authModule.logout(); return; }
    const { user } = response;
    // Check to see if the user consumer before unwrapping
    if (user.role.Consumer) {
      const isSubscribed = user.role.Consumer.subscription !== null;
      this.SET_USER_SUBSCRIPTIONS(isSubscribed);
    }
    this.SET_USER(user);
    this.SET_USER_ID(user.id);
    authModule.SET_IS_AUTHENTICATED(true);
    ApiService.setHeaderFromJWT();
  }

  @Action
  setUserIdFromStorage(): void {
    // In case the load is off a refresh, and the state is not set,
    // we must pull the userId from the token if it exists
    const userId = JwtService.getToken(USER_ID_KEY);
    // Check if the there is a JWT set, if there isn't do not proceed.
    if (!userId) { authModule.logout(); return; }
    this.SET_USER_ID(userId);
  }

  @Action
  async subscribeUserToGold(command: SubscribeUser): Promise<void> {
    const response = await UserApiService.subscribe(command);
    // If subscription is unsuccessful, response is false.
    if (!response) { this.SET_USER_SUBSCRIPTIONS(false); return; }
    this.SET_USER_SUBSCRIPTIONS(true);
  }

  @Action
  updateSubscriptionInfo(payload: { amount: string, description: string }): void {
    this.SET_SUBSCRIPTION_INFO(payload);
  }

  @Action
  async changeUserPassword(command: ChangeUserPassword): Promise<void> {
    await UserApiService.changeUserPassword(command);
  }

  @Action
  async changeUserFullName(command: ChangeUserName): Promise<void> {
    const response = await UserApiService.changeUserFullName(command);
    if (!response) { return; }
    this.SET_USER_NAME(command.new_name);
  }

  @Action
  async changeUserEmail(payload: ChangeUserEmail): Promise<void> {
    await UserApiService.changeUserEmail(payload);
  }

  @Action
  async unsubscribeUser(command: UnsubscribeUser): Promise<void> {
    const response = await UserApiService.unsubscribeUser(command);
    // If request was successful (no errors), set subscription to false.
    if (response) {
      this.SET_USER_SUBSCRIPTIONS(false);
    }
  }

  @Action
  async fetchSetupIntent(command: CreateSetupIntent): Promise<void> {
    const response = await UserApiService.fetchSetupIntent(command);
    if (!response) { return; }
    this.SET_STRIPE_CLIENT_SECRET(response.client_secret);
  }

  @Action
  async fetchPaymentIntent(command: CreatePaymentIntent): Promise<void> {
    const response = await UserApiService.fetchPaymentIntent(command);
    if (!response) { return; }
    this.SET_STRIPE_CLIENT_SECRET(response.client_secret);
  }

  @Action
  async confirmPaymentIntent(command: ConfirmPaymentIntent): Promise<void> {
    const response = await UserApiService.confirmPaymentIntent(command);
    if (!response) { this.SET_USER_SUBSCRIPTIONS(false); return; }
    this.SET_USER_SUBSCRIPTIONS(true);
  }

  @Action
  async fetchUser(query: FetchUser): Promise<void> {
    const response = await UserApiService.loadUser(query);
    if (!response) { return; }
    this.ADD_USER_TO_USERS(response.user);
  }

  @Action
  async generateReferralToken(command: GenerateReferralToken): Promise<void> {
    const response = await UserApiService.generateReferralToken(command);
    // If request was successful (no errors), set subscription to false.
    if (!response) { return; }
    this.SET_REFERRAL_TOKEN(response.referral_token);
  }

  @Action
  async fetchAllUsers(): Promise<void> {
    const response = await UserApiService.fetchAllUsers();
    if (!response) return;
    this.SET_ALL_USERS(response.users);
  }

  @Action
  async inviteSupplierUser(command: InviteSupplierUser): Promise<void> {
    await UserApiService.inviteSupplierUser(command);
  }

  @Action
  async registerAdmin(command: RegisterAdmin): Promise<void> {
    await UserApiService.registerAdmin(command);
  }
}

export default getModule(UserModule);
