const PropertyEndpoint = {
  CREATE_PROPERTY: (): string => '/properties',

  FETCH_PROPERTY: (propertyId: string): string => `/properties/${propertyId}`,

  ADD_FILL_LOCATION: (propertyId: string): string => `/properties/${propertyId}/fill_location`,

  FETCH_PROPERTY_BY_OWNER_ID: (): string => '/properties/by_owner_id',

  ARCHIVE_PROPERTY: (propertyId: string): string => `/properties/${propertyId}/archive`,
};

export default PropertyEndpoint;
