import Vue from 'vue';
import mitt from 'mitt';
import App from '@/root/Main.vue';
import router from '@/router/index';
import store from '@/store/index';
import vuetify from '@/plugins/vuetify';
import ApiService from './services/api';

Vue.config.productionTip = false;
new ApiService().init();

export const eventBus = mitt();
export default eventBus;

Vue.prototype.$colors = {
  green: '#2ecc71',
  blue: '#74b9ff',
  orange: '#f39c12',
  gold: '#f1c40f',
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
