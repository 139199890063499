<template>
  <div
    class="consumer-public"
    style="min-height: 100vh!important;"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view
        class="max-height-limit"
        style="min-height:90vh;"
      />
    </transition>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class PublicRoot extends Vue {}
</script>

<style lang="less" scoped>
.max-height-limit {
  max-height: none !important;
}

.consumer-public {
  background-color: @brandGreen;
}

</style>
