import {
  VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import {
  AuthorizeForgotPassword,
  ConfirmForgotPassword, InitiateForgotPassword,
  LoginUser, ReactivateUser,
  RegisterConsumer,
  RegisterSupplierUser,
} from '@/services/api/auth/types/command';
import store from '../index';
import AuthApiService from '../../services/api/auth';
import JwtService, { ID_REFRESH_TOKEN_KEY, ID_ACCESS_TOKEN_KEY } from '../../services/jwt';
import userModule from './user.module';
import ApiService from '../../services/api';
import { AuthState } from '../states';

@Module({ dynamic: true, store, name: 'auth' })
class Authentication extends VuexModule implements AuthState {
  refreshToken: string | undefined = JwtService.getToken(ID_REFRESH_TOKEN_KEY);

  accessToken: string | undefined = JwtService.getToken(ID_ACCESS_TOKEN_KEY);

  userEmail = '';

  passwordResetAuthorizationToken: string | null = null;

  lastUpdatedTime = new Date();

  zipCode: string | null = null;

  isAuthenticated = false;

  @Mutation
  SET_AUTH(payload: { access_token: string, refresh_token: string }): void {
    this.refreshToken = payload.refresh_token || undefined;
    this.accessToken = payload.access_token || undefined;
    JwtService.saveToken(ID_ACCESS_TOKEN_KEY, payload.access_token);
    JwtService.saveToken(ID_REFRESH_TOKEN_KEY, payload.refresh_token);
    this.isAuthenticated = true;
    ApiService.setHeaderFromJWT();
  }

  @Mutation
  SET_USER_EMAIL(email: string): void {
    this.userEmail = email;
  }

  @Mutation
  SET_ACCESS_TOKEN(accessToken: string): void {
    ApiService.setAuthHeader(accessToken);
    this.accessToken = accessToken;
    JwtService.saveToken(ID_ACCESS_TOKEN_KEY, accessToken);
  }

  @Mutation
  SET_IS_AUTHENTICATED(isAuthenticated: boolean): void {
    this.isAuthenticated = isAuthenticated;
  }

  @Mutation
  SET_PASSWORD_AUTH_TOKEN(token: string): void {
    this.passwordResetAuthorizationToken = token || null;
  }

  @Mutation
  PURGE_AUTH(): void {
    JwtService.destroyAllTokens();
    ApiService.removeAuthHeader();
    this.isAuthenticated = false;
    this.accessToken = undefined;
    this.refreshToken = undefined;
  }

  @Mutation
  SET_ZIPCODE(zipCode: string): void {
    this.zipCode = zipCode;
  }

  @Action
  async login(user: LoginUser): Promise<void> {
    const response = await AuthApiService.login(user);
    // If there is no response, an error was thrown.
    if (!response) {
      return;
    }
    this.SET_AUTH({
      access_token: response.access_token,
      refresh_token: response.refresh_token,
    });
    userModule.SET_USER_ID(response.user_id);
  }

  @Action
  logout(): void {
    this.PURGE_AUTH();
  }

  @Action
  async register(payload: RegisterConsumer): Promise<void> {
    this.SET_USER_EMAIL(payload.email);
    const response = await AuthApiService.register(payload);
    if (!response) {
      return;
    }
    // Register returns a userId, set that for use in utility account registration
    userModule.SET_USER_ID(response.user_id);
    this.SET_ACCESS_TOKEN(response.recent_registration_access_token);
    this.SET_ZIPCODE(payload.zipcode);
  }

  @Action
  async registerSupplierUser(payload: RegisterSupplierUser): Promise<void> {
    this.SET_USER_EMAIL(payload.email);
    const response = await AuthApiService.registerSupplierUser(payload);
    if (!response) {
      return;
    }
    // Register returns a userId, set that for use in utility account registration
    userModule.SET_USER_ID(response.user_id);
    this.SET_ACCESS_TOKEN(response.recent_registration_access_token);
  }

  @Action
  async activateUser(confirmationToken: string): Promise<void> {
    const response = await AuthApiService.activateUser(confirmationToken);
    if (!response) {
      return;
    }

    this.SET_AUTH({
      access_token: response.access_token,
      refresh_token: response.refresh_token,
    });
    userModule.SET_USER_ID(response.user_id);
  }

  @Action
  async reactivateUser(command: ReactivateUser): Promise<void> {
    await AuthApiService.retryUserActivation(command);
  }

  @Action
  async initiateForgotPassword(command: InitiateForgotPassword): Promise<void> {
    await AuthApiService.initiateForgotPassword(command);
  }

  @Action
  async authorizeForgotPassword(command: AuthorizeForgotPassword): Promise<void> {
    const response = await AuthApiService.authorizeForgotPassword(command);
    if (!response) { return; }
    // Set the authorization token for confirmation.
    this.SET_PASSWORD_AUTH_TOKEN(response.authorization_token);
  }

  @Action
  async confirmForgotPassword(command: ConfirmForgotPassword): Promise<void> {
    await AuthApiService.confirmForgotPassword(command);
  }

  @Action
  setAccessTokenToHeader(accessToken: string): void {
    this.SET_ACCESS_TOKEN(accessToken);
  }

  /**
   * Checks if the currently stored JWT is active, if not,
   * request new access tokens. This function is called when the
   * ConsumerRoot is mounted and before every logged-in page change.
   */
  @Action
  async refreshJWT(): Promise<void> {
    // Token is expired, refresh the user's tokens.
    const response = await AuthApiService.refreshTokens();
    if (!response) {
      this.PURGE_AUTH();
      return;
    }
    // Delete current tokens, and reset with new tokens.
    this.SET_AUTH({
      access_token: response.access_token,
      refresh_token: response.refresh_token,
    });
  }
}

export default getModule(Authentication);
