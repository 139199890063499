import ApiService from '../index';
import UtilityAccountEndpoint from './types/endpoints';
import {
  MarkReadingAsCompleted, MarkReadingOutOfSync,
  RegisterPendingContactSwitch,
  RegisterUtilityAccount,
  StopNotifications,
} from './types/command';
import { FetchPropertyAverages, FetchUtilityAccount } from './types/query';
import {
  FetchAllUtilityAccountsResponse,
  FetchPropertyAveragesResponse,
  FetchUtilityAccountResponse,
} from './types/read';

export default class UtilityAccountApiService {
  static async registerUtilityAccount(command: RegisterUtilityAccount): Promise<void> {
    await ApiService.post(
      UtilityAccountEndpoint.UTILITY_ACCOUNT(),
      command,
    );
  }

  static async fetchUtilityAccount(query: FetchUtilityAccount): Promise<FetchUtilityAccountResponse | undefined> {
    const response = await ApiService.get(
      UtilityAccountEndpoint.FETCH_UTILITY_ACCOUNT(),
      query,
      true,
      true,
    );
    return response?.data;
  }

  static async fetchAllUtilityAccounts(): Promise<FetchAllUtilityAccountsResponse| undefined> {
    const response = await ApiService.get(
      UtilityAccountEndpoint.FETCH_ALL(),
      {},
    );
    return response?.data;
  }

  static async stopExpirationReminders(command: StopNotifications): Promise<void> {
    await ApiService.post(
      UtilityAccountEndpoint.STOP_REMINDERS(
        command.utilityaccount_id,
        command.property_id,
        command.service_reference_number,
      ),
      command,
    );
  }

  static async registerPendingContractSwitch(command: RegisterPendingContactSwitch): Promise<void> {
    await ApiService.post(
      UtilityAccountEndpoint.REGISTER_SWITCH(
        command.utilityaccount_id,
        command.property_id,
        command.service_reference_number,
      ),
    );
  }

  static async fetchAverageUsages(query: FetchPropertyAverages):
    Promise<FetchPropertyAveragesResponse | undefined> {
    const response = await ApiService.get(
      UtilityAccountEndpoint.FETCH_AVG_USAGES_FOR_PROPERTY(
        query.utility_account_id,
        query.property_id,
      ),
      undefined,
      false,
    );
    return response?.data;
  }

  static async markReadingAsCompleted(command: MarkReadingAsCompleted): Promise<void> {
    await ApiService.post(
      UtilityAccountEndpoint.MARK_READING_AS_PAID(
        command.utilityaccount_id,
        command.property_id,
        command.service_reference_number,
      ),
      command,
    );
  }

  static async markReadingOutOfSync(command: MarkReadingOutOfSync): Promise<void> {
    await ApiService.post(
      UtilityAccountEndpoint.MARK_READING_OUT_OF_SYNC(
        command.utilityaccount_id,
        command.property_id,
        command.service_reference_number,
      ),
      command,
    );
  }
}
