export enum StatusCodes {
    MaintenanceMode = 503,
}

export enum ErrorKind {
    Generic = 'An unknown error has occurred. Please try again soon.',
    Network = "Unable to connect to the server. Please make sure you're connected to the internet and try again soon."
}

export enum ClientErrorKind {
    UtilityAccountDoesNotExist = 'You currently do not have a utility account in your GridLite account.',
    InactiveAccount = 'Your account is currently inactive, check your email for the confirmation link to activate.',
    IncorrectPassword = 'Incorrect email and/or password.',
    ExpiredSignature = 'Session has expired. Please sign in to continue.',
    AlreadyRegistered = 'A user with this email has already registered.',
    InvalidResetPin = 'Invalid reset pin, please try again.',
    TooManyResetFails = 'Too many failed reset attempts. Please initiate another reset later.',
    ZipCodeIsInvalid = 'We don\'t support your utility yet, but we are quickly adding more areas so check back soon!',
    Generic = 'An error has occurred. Please try again soon.',
    BadPermission = 'Session has expired. Please sign in to continue.',
    SupplierDoesNotExist = 'Requested supplier does not exist.',
    AddressValidationFailed = 'We couldn\'t validate your address, please re-enter and try again.',
    WillCallServiceAlreadyExists = 'You already have an opened order for that fuel type.',
}

export enum APIErrorKind {
    UtilityAccountDoesNotExist = 'utility account with consumer_id_of_owner',
    InactiveAccount = 'is inactive or disabled',
    IncorrectPassword = 'supplied password',
    ExpiredSignature = 'Server failed to authenticate the request',
    // Regex looks for "user...does not exist"
    UserDoesNotExist = '(?=.*?(user))(?=.*?(does not exist))',
    // Regex looks for "electric_supplier...does not exist"
    SupplierDoesNotExist = '(?=.*?(supplier))(?=.*?(does not exist))',
    AlreadyRegistered = 'is already registered',
    Network = "Unable to connect to the server. Please make sure you're connected to the internet and try again soon.",
    InvalidResetPin = 'argument `pin` is invalid: supplied pin',
    TooManyResetFails = 'too many failed attempts for password reset',
    ZipCodeIsInvalid = 'zipcode is invalid',
    BadPermission = 'The agent does not have sufficient permissions',
    FailedAuth = 'failed to authenticate the request',
    AddressValidationFailed = 'failed to validate address',
    WillCallServiceAlreadyExists = 'here is already an open will-call service request for this oil kind',
}
