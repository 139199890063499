const AuthEndpoints = {
  // Signs user up with GridLite account, issues a "verify email" email
  REGISTER_CONSUMER: (): string => '/users/consumer',

  // Signs electric_supplier user up with GridLite account, issues a "verify email" email
  REGISTER_SUPPLIER: (): string => '/users/supplier_employee',

  // Logs user into their account
  LOGIN: (): string => '/users/authenticate',

  // Refreshes users authentication, access_token expires in 1 hour, fresh renews lease
  REFRESH: (): string => '/users/authenticate/refresh',

  // Clears stored access token, revokes authorization, logs user out.
  LOGOUT: (userId: string): string => `/users/${userId}/logout`,

  // Sends an activation JWT from SignupConfirmation URL to activate the user's account.
  ACTIVATE: (): string => '/users/activate',

  // Initiates password reset from the public login page.
  INITIATE_FORGOT_PASSWORD: (): string => '/users/password_reset/initiate',

  AUTHORIZE_FORGOT_PASSWORD: (): string => '/users/password_reset/authorize',

  CONFIRM_FORGOT_PASSWORD: (): string => '/users/password_reset/confirm',

  // Resends activation email to user
  RETRY_ACTIVATE: (): string => '/users/activate/retry',
};

export default AuthEndpoints;
