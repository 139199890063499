export enum SubscriptionKind {
    monthly = 'Monthly',
    annual = 'Annual'
}

export enum SignupStatus {
    UserSignup,
    UtilitySignup,
    GoldSignup,
    SignupCompleted,
    AddFillLocation,
    PaymentForm,
    WillCallOrderForm
}

export enum OfferingSortKinds {
    EstimatedCost = 'estimated_cost'
}

export enum Utilities {
    Eversource = 'Eversource',
    UI = 'UI',
}

export enum SortName {
    Savings = 'monthly savings',
    Renewability = 'renewability',
    TermLength = 'length of term from shortest to longest',
    Reputation = 'supplier reputation from best to worst'
}
