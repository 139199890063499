const UtilityAccountEndpoint = {
  FETCH_AVG_USAGES_FOR_PROPERTY: (utilityAccountId: string, propertyId: string):
    string => `/electric_utilityaccounts/${utilityAccountId}/properties/${propertyId}/kwh_usage_average_by_month`,

  UTILITY_ACCOUNT: (): string => '/electric_utilityaccounts',

  FETCH_UTILITY_ACCOUNT: (): string => '/electric_utilityaccounts/by_owner_id',

  STOP_REMINDERS: (utilityAccountId: string, propertyId: string, srn: string):
    string => `/electric_utilityaccounts/${utilityAccountId}/properties/${propertyId}/meters/${srn}/register_pending_switch`,

  FETCH_ALL: (): string => '/electric_utilityaccounts',

  REGISTER_SWITCH: (utilityAccountId: string, propertyId: string, srn: string):
      string => `/electric_utilityaccounts/${utilityAccountId}/properties/${propertyId}/meters/${srn}/register_pending_switch`,

  MARK_READING_AS_PAID: (utilityAccountId: string, propertyId: string, srn: string):
      string => `/electric_utilityaccounts/${utilityAccountId}/properties/${propertyId}/meters/${srn}/split_bill/complete_bill_pay`,

  MARK_READING_OUT_OF_SYNC: (utilityAccountId: string, propertyId: string, srn: string):
      string => `/electric_utilityaccounts/${utilityAccountId}/properties/${propertyId}/meters/${srn}/split_bill/bill_pay_out_of_sync`,

};

export default UtilityAccountEndpoint;
