const UserEndpoints = {
  FETCH_USER: (userId: string): string => `/users/${userId}`,

  FETCH_ALL: (): string => '/users',

  // Subscribes user to GridLite Gold, sends them welcome email.
  SUBSCRIBE: (userId: string): string => `/users/${userId}/subscribe`,

  // Unsubscribes user from GridLite Gold
  UNSUBSCRIBE: (userId: string): string => `/users/${userId}/unsubscribe`,

  FETCH_PAYMENT_INTENT: (userId: string): string => `/users/${userId}/payment/generate_subscription_intent`,

  FETCH_SETUP_INTENT: (userId: string): string => `/users/${userId}/payment/generate_setup_intent`,

  CHANGE_PASSWORD: (userId: string): string => `/users/${userId}/change_password`,

  CHANGE_NAME: (userId: string): string => `/users/${userId}/change_name`,

  CHANGE_EMAIL: (userId: string): string => `/users/${userId}/change_email`,

  GENERATE_REFERRAL_TOKEN: (userId: string): string => `/users/${userId}/referral_token`,

  CONFIRM_PAYMENT_INTENT: (userId: string): string => `/users/${userId}/confirm_payment_intent`,

  INVITE_SUPPLIER_USER: (): string => '/users/supplier_employee/invite',

  REGISTER_ADMIN: (): string => '/users/admin',
};

export default UserEndpoints;
