import Vue from 'vue';
import Vuex from 'vuex';

import {
  AuthState,
  ElectricSupplierState,
  ErrorState,
  OilAccountState,
  OilSupplierState,
  PropertyState,
  PublicState,
  UserState,
  UtilityAccountState,
  UtilityState,
} from './states';

Vue.use(Vuex);

export interface RootState {
  auth: AuthState;
  electric_supplier: ElectricSupplierState;
  oil_account: OilAccountState;
  property: PropertyState;
  error: ErrorState;
  public: PublicState;
  user: UserState;
  utility_account: UtilityAccountState;
  oil_supplier: OilSupplierState,
  utility: UtilityState;
}

export default new Vuex.Store<RootState>({});
