import {
  VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import { Utility } from '@/models/utility.model';
import { FetchDistributionRates, FetchUtilityByZipCode } from '@/services/api/utility/types/query';
import store from '../index';
import UtilityApiService from '../../services/api/utility';
import { UtilityState } from '../states';

@Module({ dynamic: true, store, name: 'utility' })
class UtilityModule extends VuexModule implements UtilityState {
  utility: Utility | null = null;

  estimatedDistributionCost = 0;

  utilities: { [key: string]: Utility } = {};

  @Mutation
  SET_UTILITY(utility: Utility): void {
    this.utility = utility;
  }

  @Mutation
  SET_UTILITIES(utilities: Utility[]): void {
    utilities.forEach((u) => {
      this.utilities[u.id] = u;
    });
  }

  @Mutation
  SET_ESTIMATED_DISTRO_RATE(estimatedDistroCost: number): void {
    this.estimatedDistributionCost = estimatedDistroCost;
  }

  @Action
  async fetchUtilityByZipCode(query: FetchUtilityByZipCode): Promise<void> {
    const response = await UtilityApiService.fetchUtility(query);
    if (!response) { return; }
    const [utility] = response.utilities;
    if (!utility) { return; }
    this.SET_UTILITY(utility);
  }

  @Action
  async fetchDistributionRate(query: FetchDistributionRates): Promise<void> {
    const response = await UtilityApiService.fetchDistributionRate(query);
    if (!response) { return; }
    this.SET_ESTIMATED_DISTRO_RATE(response.estimated_distribution_cost);
  }

  @Action
  async fetchAllUtilities(): Promise<void> {
    const response = await UtilityApiService.fetchAllUtilities();
    if (!response) { return; }
    this.SET_UTILITIES(response.utilities);
  }
}

export default getModule(UtilityModule);
