import {
  VuexModule, Module, Action, getModule, Mutation,
} from 'vuex-module-decorators';
import { ArchiveProperty, RegisterProperty } from '@/services/api/property/types/command';
import { FetchProperty, FetchPropertyByOwner } from '@/services/api/property/types/query';
import { Property } from '@/models/property.model';
import store from '../index';
import PropertyApiService from '../../services/api/property';
import { LatLng, PropertyState, RegisterPropertyFields } from '../states';

@Module({ dynamic: true, store, name: 'property' })
class PropertyModule extends VuexModule implements PropertyState {
  currentProperty: Property | null = null;

  currentPropertyId = '';

  savedProperty: RegisterPropertyFields | null = null;

  currentPropertyLatLng: LatLng | null = null;

  properties: { [key: string]: Property } = {};

  @Mutation
  SET_PROPERTY_ID(propertyId: string): void {
    this.currentPropertyId = propertyId;
  }

  @Mutation
  SET_PROPERTY(property: Property | null): void {
    this.currentProperty = property;
  }

  @Mutation
  SET_PROPERTY_LAT_LNG(latLng: LatLng | null): void {
    this.currentPropertyLatLng = latLng;
  }

  @Mutation
  SAVE_PROPERTY_PAYLOAD(payload: RegisterPropertyFields): void {
    this.savedProperty = payload;
  }

  @Mutation
  ADD_PROPERTY_TO_PROPERTIES(property: Property): void {
    this.properties[property.id] = property;
  }

  @Action
  async createProperty(command: RegisterProperty): Promise<void> {
    const response = await PropertyApiService.createProperty(command);
    if (!response?.data.property_id) {
      return;
    }
    this.SET_PROPERTY_ID(response.data.property_id);
  }

  @Action
  saveProperty(property: RegisterPropertyFields): void {
    this.SAVE_PROPERTY_PAYLOAD(property);
  }

  @Action
  async archiveProperty(command: ArchiveProperty): Promise<void> {
    await PropertyApiService.archiveProperty(command);
    this.SET_PROPERTY(null);
  }

  @Action
  async fetchProperty(query: FetchProperty): Promise<void> {
    // If the property is already cached in the state, don't refetch it.
    if (this.properties[query.property_id]) {
      this.SET_PROPERTY(this.properties[query.property_id]);
      const { lat, lon: lng } = this.properties[query.property_id].address.exact_address;
      this.SET_PROPERTY_LAT_LNG({ lat, lng });
      return;
    }
    const response = await PropertyApiService.fetchProperty(query);
    if (!response) {
      return;
    }
    this.SET_PROPERTY(response.property);
    this.SET_PROPERTY_LAT_LNG({
      lng: response.property.address.exact_address.lon,
      lat: response.property.address.exact_address.lat,
    });
    this.ADD_PROPERTY_TO_PROPERTIES(response.property);
  }

  @Action
  async fetchPropertyByOwner(query: FetchPropertyByOwner): Promise<void> {
    const response = await PropertyApiService.fetchPropertyByOwnerId(query);
    if (!response) {
      return;
    }
    this.SET_PROPERTY(response.property);
  }
}

export default getModule(PropertyModule);
