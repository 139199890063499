import ApiService from '../index';
import SupplierEndpoint from './types/endpoints';
import { FetchSupplierById, FetchSuppliers } from './types/query';
import {
  FetchSupplierResponse,
  FetchSuppliersResponse,
} from './types/read';

export default class ElectricSupplierApiService {
  static async fetchSuppliers(payload: FetchSuppliers): Promise<FetchSuppliersResponse | undefined> {
    const response = await ApiService.get(
      SupplierEndpoint.FETCH_OFFERINGS(),
      payload,
      false,
    );
    return response?.data;
  }

  static async fetchSupplier(query: FetchSupplierById): Promise<FetchSupplierResponse | undefined> {
    const response = await ApiService.get(
      SupplierEndpoint.FETCH_SUPPLIER(query.supplier_id),
    );
    return response?.data;
  }
}
